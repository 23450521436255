import React from 'react';
import s from './Promos.module.scss';
import { promos } from '../../../../assets/imgs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

function Promos() {
  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  const settings = {
    arrows: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '60px',
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className={s.promosContainer}>
      {isMobile ? (
        <Slider {...settings}>
          {Object.values(promos).map((item, index) => {
            return (
              <div key={index} className={s.promosSection}>
                <img className={s.promosImg} alt="promos" src={item} />
              </div>
            );
          })}
        </Slider>
      ) : (
        Object.values(promos).map((item, index) => {
          return (
            <div key={index} className={s.promosSection}>
              <img className={s.promosImg} alt="promos" src={item} />
            </div>
          );
        })
      )}
    </div>
  );
}

export default Promos;
