import React from 'react';
import s from './BenefitSection.module.scss';
import { useTranslation } from '../../../context/LanguageContext';
import BenefitOptions from './BenefitOptions/BenefitOptions';
import BenefitPoints from './BenefitPoints/BenefitPoints';
import { useMediaQuery } from 'react-responsive';
import { images } from '../../../assets/imgs';
import BenefitOptionsDesktop from './BenefitOptionsDesktop/BenefitOptionsDesktop';

function BenefitSection() {
  const t = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  return (
    <div className={s.benefitSection}>
      {isMobile && <div className={s.affordable}>{t('Affordable!')}</div>}
      {!isMobile && (
        <div className={s.secondTitle}>
          <div>{t('Now the')}</div>
          <img className={s.fire} alt="fire" src={images.fire} />
          <div className={s.hottest}>{t('Hottest')}</div>
          <div className={s.secondLine}>{t('Entertainment is More Affordable!')}</div>
        </div>
      )}
      {isMobile ? <BenefitOptions /> : <BenefitOptionsDesktop />}
      {isMobile && <BenefitPoints />}
    </div>
  );
}

export default BenefitSection;
