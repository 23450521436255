import React from 'react';
import s from './RightArrow.module.scss';
import { images } from '../../../../../assets/imgs';
import classNames from 'classnames';

function RightArrow({ onRightArrowClick, isApkWindowOpen, hideRightArrow }) {
  return (
    <div onClick={() => onRightArrowClick()}>
      <img
        className={classNames(s.slideArrowRight, { [s.changeTop]: !isApkWindowOpen })}
        alt="SlideArrowRight"
        src={hideRightArrow ? images.SlideArrowRight : images.SlideArrowRightDisable}
      />
    </div>
  );
}

export default RightArrow;
