import React, { useRef } from 'react';
import s from './HowToInstallSlides.module.scss';
import { screenShots } from '../../../../assets/imgs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveSlide, setActiveSlide } from '../../../../redux/slices/app.slices';
import SlidesNumbers from './SlidesNumbers/SlidesNumbers';
import RightArrow from './RightArrow/RightArrow';
import LeftArrow from './LeftArrow/LeftArrow';
import { useMediaQuery } from 'react-responsive';
import HowToInstallContent from './HowToInstallContent/HowToInstallContent';

function HowToInstallSlides({ isApkWindowOpen }) {
  const dispatch = useDispatch();
  const activeSlide = useSelector(selectActiveSlide);
  const sliderRef = useRef(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });
  const hideRightArrow = !(activeSlide === 6);
  const hideLeftArrow = !(activeSlide === 0);

  const settings = isMobile
    ? {
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '100px',
        swipe: false,
        slickGoTo: activeSlide,
        afterChange: (index) => {
          dispatch(setActiveSlide(index));
        },
      }
    : {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slickGoTo: activeSlide,
        swipe: false,
        infinite: true,
        afterChange: (index) => {
          dispatch(setActiveSlide(index));
        },
      };

  const handleClick = (index) => {
    setActiveSlide(index);
    sliderRef.current.slickGoTo(index);
  };

  const onRightArrowClick = () => {
    if (activeSlide < 6) {
      const nextSlideIndex = activeSlide + 1;
      dispatch(setActiveSlide(nextSlideIndex));
      sliderRef.current.slickGoTo(nextSlideIndex);
    }
  };

  const onLeftArrowClick = () => {
    if (activeSlide > 0) {
      const nextSlideIndex = activeSlide - 1;
      dispatch(setActiveSlide(nextSlideIndex));
      sliderRef.current.slickGoTo(nextSlideIndex);
    }
  };

  return (
    <div className={s.howToInstallSlidesContainer}>
      {!isMobile && (
        <div className={s.leftArrowDesktopContainer}>
          <LeftArrow
            onLeftArrowClick={onLeftArrowClick}
            hideLeftArrow={hideLeftArrow}
            isApkWindowOpen={isApkWindowOpen}
          />
        </div>
      )}
      <div className={s.howToInstallSlides}>
        <Slider {...settings} ref={sliderRef}>
          {Object.values(screenShots).map((screens, index) => {
            return (
              <div key={index} className={s.screenShotsContainer}>
                <img
                  className={classNames(s.screens, { [s.active]: activeSlide === index })}
                  alt="screens"
                  src={screens}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      {!isMobile && <HowToInstallContent activeSlide={activeSlide} handleClick={handleClick} />}
      {!isMobile && (
        <div className={s.rightArrowDesktopContainer}>
          <RightArrow
            onRightArrowClick={onRightArrowClick}
            hideRightArrow={hideRightArrow}
            isApkWindowOpen={isApkWindowOpen}
          />
        </div>
      )}
      {isMobile && (
        <div className={classNames(s.leftArrowContainer, { [s.changeTop]: !isApkWindowOpen })}>
          <LeftArrow
            onLeftArrowClick={onLeftArrowClick}
            isApkWindowOpen={isApkWindowOpen}
            hideLeftArrow={hideLeftArrow}
          />
        </div>
      )}
      {isMobile && (
        <div className={classNames(s.rightArrowContainer, { [s.changeTop]: !isApkWindowOpen })}>
          <RightArrow
            onRightArrowClick={onRightArrowClick}
            isApkWindowOpen={isApkWindowOpen}
            hideRightArrow={hideRightArrow}
          />
        </div>
      )}
      {isMobile && <SlidesNumbers handleClick={handleClick} activeSlide={activeSlide} />}
    </div>
  );
}

export default HowToInstallSlides;
