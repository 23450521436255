import DownloadPage from '../components/DownloadPage/DownloadPage';
import LandingPage from '../components/LandingPage/LandingPage';
import { APP_PAGES } from '../constant/app.constat';

const { LANDING_PAGE, DOWNLOAD_PAGE } = APP_PAGES;

export const PAGES_CONFIGS = {
  [LANDING_PAGE]: {
    Component: LandingPage,
  },
  [DOWNLOAD_PAGE]: {
    Component: DownloadPage,
  },
};
