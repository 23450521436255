import React from 'react';
import s from './VideoPlayerButton.module.scss';
import { images } from '../../../../../assets/imgs';
import { useDispatch } from 'react-redux';
import { setVideoPlayerActive } from '../../../../../redux/slices/app.slices';
import { useTranslation } from '../../../../../context/LanguageContext';

function VideoPlayerButton() {
  const dispatch = useDispatch();
  const t = useTranslation();
  return (
    <div onClick={() => dispatch(setVideoPlayerActive(true))} className={s.videoPlayerContainer}>
      <div className={s.videoPlayerButton}>
        <div className={s.videoPlayerIconContainer}>
          <img className={s.videoPlayerIcon} alt="video Player Icon" src={images.videoPlayerIcon} />
        </div>
        <div className={s.videoPlayerText}>
          <div>{t('Watch a short video on')}</div>
          <div className={s.videoPlayerText2}>
            <div className={s.greenText}>{t('How to download')}</div>
            <div>{t('the APP')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayerButton;
