import React from 'react';
import s from './SlidesNumbers.module.scss';
import classNames from 'classnames';

const screenNumbers = new Array(7).fill(null);

function SlidesNumbers({ handleClick, activeSlide }) {
  return (
    <div className={s.numbersConstainer}>
      {screenNumbers.map((_, index) => {
        return (
          <div
            key={index}
            className={classNames(s.numbers, { [s.active]: activeSlide === index })}
            onClick={() => handleClick(index)}>
            {index + 1}
          </div>
        );
      })}
    </div>
  );
}

export default SlidesNumbers;
