import React from 'react';
import s from './FooterSection.module.scss';
import FixedButtons from '../FixedButtons/FixedButtons';
import APKWindow from '../../DownloadPage/APKWindow/APKWindow';

function FooterSection() {
  return (
    <div className={s.footerSection}>
      <div className={s.footerContent}>
        <div className={s.apkWindowFooter}>
          <APKWindow footer />
        </div>
        <FixedButtons />
      </div>
    </div>
  );
}

export default FooterSection;
