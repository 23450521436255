import React, { useEffect, useState } from 'react';
import s from './DownloadPage.module.scss';
import APKWindow from './APKWindow/APKWindow';
import HowToInstall from './HowToInstall/HowToInstall';
import { useMediaQuery } from 'react-responsive';
import HowToInstallDesktop from './HowToInstall/HowToInstallDesktop/HowToInstallDesktop';

function DownloadPage() {
  const [isApkWindowOpen, setApkWindow] = useState(true);

  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  useEffect(() => {
    if (!isMobile) {
      setApkWindow(false);
    }
  }, [isMobile]);

  return (
    <div className={s.downloadPage}>
      {isMobile && isApkWindowOpen && <APKWindow setApkWindow={setApkWindow} />}
      {isMobile ? (
        <HowToInstall isApkWindowOpen={isApkWindowOpen} isMobile={isMobile} />
      ) : (
        <HowToInstallDesktop />
      )}
    </div>
  );
}

export default DownloadPage;
