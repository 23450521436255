import { useSelector } from 'react-redux';
import { selectActivePage } from './redux/slices/app.slices';
import { PAGES_CONFIGS } from './configs/pages.configs';

function App() {
  const activePage = useSelector(selectActivePage);
  const activePageComponent = PAGES_CONFIGS[activePage];

  return <activePageComponent.Component />;
}

export default App;
