import React from 'react';
import s from './SloteProviders.module.scss';
import { images, providers } from '../../../../assets/imgs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

function SloteProviders() {
  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  const settings = {
    arrows: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '150px',
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
  };
  return (
    <div className={s.sloteProviders}>
      {isMobile ? (
        <Slider {...settings}>
          {Object.values(providers).map((logo, index) => {
            const providerClass = `provider${index}`;
            return (
              <div key={index} className={s.sloteProvidersContainer}>
                <div>
                  <img className={s.top} alt="top icon" src={images.top} />
                  <img className={s[providerClass]} alt="providers logo" src={logo} />
                </div>
              </div>
            );
          })}
        </Slider>
      ) : (
        Object.values(providers).map((logo, index) => {
          const providerClass = `provider${index}`;
          return (
            <div className={s.sloteProvidersContainer}>
              <div>
                <img className={s.top} alt="top icon" src={images.top} />
                <img className={s[providerClass]} alt="providers logo" src={logo} />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default SloteProviders;
