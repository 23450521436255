import React from 'react';
import s from './FixedButtons.module.scss';
import { useTranslation } from '../../../context/LanguageContext';
import { useDispatch } from 'react-redux';
import { setActivePage } from '../../../redux/slices/app.slices';
import { APP_PAGES } from '../../../constant/app.constat';

const { DOWNLOAD_PAGE } = APP_PAGES;

function FixedButtons() {
  const t = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={s.fixedButtonsContainer}>
      <div className={s.fixedButtons}>
        <div onClick={() => dispatch(setActivePage(DOWNLOAD_PAGE))} className={s.howtoIButton}>
          {t('HOW TO INSTALL')}
        </div>
        <a
          href="https://assets-inter.energaming.services/apk/pmapp.apk?v=2"
          download="pmapp.apk"
          target="_blank"
          className={s.downloadButton}>
          {t('DOWNLOAD')}
        </a>
      </div>
    </div>
  );
}

export default FixedButtons;
