import en from './jsons/en.json';
import sw from './jsons/sw.json';

const languages = {
  en: en,
  sw: sw,
};

const getActiveLanguages = (lang) => languages[lang] || languages['en'];

export default getActiveLanguages;
