import React from 'react';
import s from './CasinoSection.module.scss';
import Promos from './Promos/Promos';
import { images } from '../../../assets/imgs';
import { useTranslation } from '../../../context/LanguageContext';
import SloteProviders from './SloteProviders/SloteProviders';
import { useSelector } from 'react-redux';
import { selectActiveLanguage } from '../../../redux/slices/app.slices';

function CasinoSection() {
  const t = useTranslation();
  const activeLanguage = useSelector(selectActiveLanguage);

  return (
    <div className={s.casinoSection}>
      <Promos />
      <img className={s.dice} alt="dice" src={images.dice} />
      <img className={s.card} alt="card" src={images.card} />
      <div className={s.casinoPresent}>
        <div className={s.casinoTitle}>
          <img className={s.casinoIcon} alt="casino icon" src={images.casinoIcon} />
          <div>{t('Casino')}</div>
        </div>
        <div className={s.cashBackSection}>
          {activeLanguage === 'en' ? (
            <div className={s.upTotext}>
              <div>{t('Up')}</div>
              <div>{t('to')}</div>
            </div>
          ) : (
            <div>{t('Up to')}</div>
          )}
          <div className={s.persent}>10%</div>
          <div>
            <div className={s.greenText}>{t('Casino')}</div>
            <div>{t('Cashback')}</div>
          </div>
        </div>
        <div className={s.casinoSlotes}>
          <div className={s.countOfSlotes}>12000+</div>
          <div className={s.casinoSlotesText}>
            {t('Casino')}
            <div className={s.greenText}>{t('SLOTES')}</div>
          </div>
        </div>
      </div>
      <SloteProviders />
    </div>
  );
}

export default CasinoSection;
