// export function getLanguageFromUrl() {
//   const splitUrl = window.location.href.split('/')[3];
//   return splitUrl ? splitUrl : 'en';
// }

export function getLanguageFromUrl() {
  const splitUrl = window.location.href.split('/')[3];
  const languageCode = splitUrl.split('.')[0];
  return languageCode ? languageCode : 'en';
}
