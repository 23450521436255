import React from 'react';
import s from './HowToInstallTexts.module.scss';
import { howToInstallTexts } from '../../../../constant/app.constat';
import { useTranslation } from '../../../../context/LanguageContext';

function HowToInstallTexts({ activeSlide }) {
  const t = useTranslation();

  return (
    <div className={s.HowToInstallTextsContainer}>
      {Object.values(howToInstallTexts).map((text, index) => {
        return (
          <div key={index} className={s.howtoinstalltext}>
            {activeSlide === index && t(text)}
          </div>
        );
      })}
    </div>
  );
}

export default HowToInstallTexts;
