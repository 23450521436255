import React from 'react';
import s from './HowToInstallContent.module.scss';
import HowToInstallTexts from '../../HowToInstallTexts/HowToInstallTexts';
import SlidesNumbers from '../SlidesNumbers/SlidesNumbers';
import VideoPlayerButton from '../../VideoPlayer/VideoPlayerButton/VideoPlayerButton';
import VideoSection from '../../VideoPlayer/VideoSection/VideoSection';
import { selectVideoPlayer } from '../../../../../redux/slices/app.slices';
import { useSelector } from 'react-redux';
import APKWindow from '../../../APKWindow/APKWindow';
import DownloadButton from '../../DownloadButton/DownloadButton';

function HowToInstallContent({ activeSlide, handleClick }) {
  const isPlayerActive = useSelector(selectVideoPlayer);

  return (
    <div className={s.howToInstallContent}>
      <HowToInstallTexts activeSlide={activeSlide} />
      <SlidesNumbers handleClick={handleClick} activeSlide={activeSlide} />
      <VideoPlayerButton />
      <div className={s.APKWindowMargin}>
        <APKWindow footer />
      </div>
      <div className={s.downloadButtonContainer}>
        <DownloadButton />
      </div>
      {isPlayerActive && <VideoSection />}
    </div>
  );
}

export default HowToInstallContent;
