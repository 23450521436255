import React from 'react';
import s from './VideoSection.module.scss';
import { images } from '../../../../../assets/imgs';
// import ReactPlayer from 'react-player';
import VideoAppDownload from '../../../../../assets/imgs/VideoAppDownload.mp4';
import { useDispatch } from 'react-redux';
import { setVideoPlayerActive } from '../../../../../redux/slices/app.slices';
import { useMediaQuery } from 'react-responsive';

function VideoSection() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  return (
    <div>
      <div className={s.videeSectionBack}></div>
      <div onClick={() => dispatch(setVideoPlayerActive(false))} className={s.videoCloseButton}>
        <img className={s.crossIcon} alt="cross Icon" src={images.crossIcon} />
      </div>
      <div>
        <video controls className={s.videoSection}>
          <source src={VideoAppDownload} type="video/mp4" />
        </video>
      </div>
      {/* <ReactPlayer
        className={s.videoSection}
        url={VideoAppDownload}
        controls
        playing
        loop
        width="80%"
        height="80%"
      /> */}
    </div>
  );
}

export default VideoSection;
