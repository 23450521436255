import React from 'react';
import s from './BenefitPoints.module.scss';
import { benefitPoints } from '../../../../constant/app.constat';
import { useTranslation } from '../../../../context/LanguageContext';
import { images } from '../../../../assets/imgs';

function BenefitPoints() {
  const t = useTranslation();
  return (
    <div className={s.benefitPoints}>
      {Object.values(benefitPoints).map((point, index) => {
        return (
          <div key={index} className={s.pointSection}>
            <img className={s.pointLogo} alt="benefit point logo" src={images.benefitPointLogo} />
            <div className={s.pointText}>{t(point)}</div>
          </div>
        );
      })}
    </div>
  );
}

export default BenefitPoints;
