import React from 'react';
import s from './LeftArrow.module.scss';
import { images } from '../../../../../assets/imgs';
import classNames from 'classnames';

function LeftArrow({ onLeftArrowClick, isApkWindowOpen, hideLeftArrow }) {
  return (
    <div onClick={() => onLeftArrowClick()}>
      <img
        className={classNames(s.slideArrowLeft, { [s.changeTop]: !isApkWindowOpen })}
        alt="SlideArrowLeft"
        src={hideLeftArrow ? images.SlideArrowRight : images.SlideArrowLeftDisable}
      />
    </div>
  );
}

export default LeftArrow;
