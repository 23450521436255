import React from 'react';
import s from './DownloadButton.module.scss';
import { useTranslation } from '../../../../context/LanguageContext';

function DownloadButton() {
  const t = useTranslation();

  return (
    <a
      href="https://assets-inter.energaming.services/apk/pmapp.apk?v=2"
      download="pmapp.apk"
      target="_blank"
      className={s.downloadButton}>
      <div>{t('DOWNLOAD')}</div>
    </a>
  );
}

export default DownloadButton;
