import React from 'react';
import s from './BenefitOptions.module.scss';
import classNames from 'classnames';
import { BENEFIT_OPTIONS } from '../../../../constant/app.constat';
import { useTranslation } from '../../../../context/LanguageContext';
import { BENEFIT_CONFIGS } from '../../../../configs/benefit.configs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function BenefitOptions() {
  const t = useTranslation();

  const settings = {
    arrows: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '60px',
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className={s.benefitOptions}>
      <Slider {...settings}>
        {Object.keys(BENEFIT_OPTIONS).map((option, index) => {
          const text = BENEFIT_CONFIGS[option].text;
          const logo = BENEFIT_CONFIGS[option].logo;
          const backClass = BENEFIT_CONFIGS[option].backClass;
          const langs = BENEFIT_CONFIGS[option].langs;

          return (
            <div key={index} className={classNames(s.optionContainer)}>
              <div className={classNames(s.option, s[backClass])}>
                <img className={s.benefitLogo} alt="logo" src={logo} />
                <div className={s.benefitText}>{t(text)}</div>
                {langs && <div className={s.langs}>{langs}</div>}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default BenefitOptions;
