import React from 'react';
import s from './HowToInstall.module.scss';
import { useTranslation } from '../../../context/LanguageContext';
import classNames from 'classnames';
import HowToInstallSlides from './HowToInstallSlides/HowToInstallSlides';
import { images } from '../../../assets/imgs';
import VideoPlayerButton from './VideoPlayer/VideoPlayerButton/VideoPlayerButton';
import VideoSection from './VideoPlayer/VideoSection/VideoSection';
import { useSelector } from 'react-redux';
import { selectActiveSlide, selectVideoPlayer } from '../../../redux/slices/app.slices';
import DownloadButton from './DownloadButton/DownloadButton';
import HowToInstallTexts from './HowToInstallTexts/HowToInstallTexts';

function HowToInstall({ isApkWindowOpen, isMobile }) {
  const t = useTranslation();
  const isPlayerActive = useSelector(selectVideoPlayer);
  const activeSlide = useSelector(selectActiveSlide);

  return (
    <div
      className={classNames(s.howToInstall, {
        [s.closedWindow]: isApkWindowOpen,
      })}>
      <div className={s.howToInstallTitle}>{t('HOW TO INSTALL')}</div>
      <HowToInstallSlides isApkWindowOpen={isApkWindowOpen} />
      <HowToInstallTexts activeSlide={activeSlide} />
      {isMobile && (
        <div className={s.androidContainer}>
          <img className={s.androidLogo} alt="android logo" src={images.android} />
          <div className={s.downloadText}>{t('Download PMBET APP NOW')}</div>
          <div className={s.downloadButtonContainer}>
            <DownloadButton />
          </div>
        </div>
      )}
      <VideoPlayerButton />
      {isPlayerActive && <VideoSection />}
    </div>
  );
}

export default HowToInstall;
