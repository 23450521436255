import React from 'react';
import s from './HowToInstallDesktop.module.scss';
import HowToInstallSlides from '../HowToInstallSlides/HowToInstallSlides';
import { useTranslation } from '../../../../context/LanguageContext';

function HowToInstallDesktop() {
  const t = useTranslation();

  return (
    <div className={s.howToInstallDesktop}>
      <div className={s.howToInstallDesktopTitle}>{t('HOW TO INSTALL')}</div>
      <div className={s.howToInstallDesktopContainer}>
        <HowToInstallSlides />
      </div>
    </div>
  );
}

export default HowToInstallDesktop;
