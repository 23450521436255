import React from 'react';
import s from './PrematchSection.module.scss';
import { images } from '../../../assets/imgs';
import { useTranslation } from '../../../context/LanguageContext';

function PrematchSection() {
  const t = useTranslation();

  return (
    <div className={s.prematchSection}>
      <img className={s.tennisBall} alt="tennis ball" src={images.tennisBall} />
      <img className={s.football} alt="football" src={images.football} />
      <div className={s.prematchPresent}>
        <div className={s.prematchTitle}>
          <img className={s.prematchIcon} alt="prematch icon" src={images.prematchIcon} />
          <div>{t('Prematch')}</div>
        </div>
        <div className={s.prematchOption}>
          <div className={s.sports}>{`80+ ${t('Sports')}`}</div>
          <div>{`200 ${t('Countries')}`}</div>
        </div>
        <div className={s.countContainer}>
          <div>
            <div className={s.count}>
              ~<div className={s.colorGreen}>175 000+</div>
            </div>
            <div>{t('Events monthly')}</div>
          </div>
          <div className={s.greenLine}></div>
          <div>
            <div className={s.count}>
              ~<div className={s.colorGreen}>2500+</div>
            </div>
            <div>{t('Bet types')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrematchSection;
