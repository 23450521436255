import React, { useContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getActiveLanguages from '../assets/languages';
import { setActiveLanguage } from '../redux/slices/app.slices';
import { getLanguageFromUrl } from '../helpers/general';

const LanguageContext = React.createContext({});

const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch();

  window.addEventListener('message', (event) => {
    console.log(event);
  });

  const language = getLanguageFromUrl();
  const languageData = getActiveLanguages(language);

  useEffect(() => {
    dispatch(setActiveLanguage(language));
  });

  const t = useCallback((text) => languageData[text] || text, [languageData]);

  return <LanguageContext.Provider value={t}>{children}</LanguageContext.Provider>;
};

export const useTranslation = () => useContext(LanguageContext);

export default LanguageProvider;
