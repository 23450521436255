export const APP_PAGES = {
  LANDING_PAGE: 'LANDING_PAGE',
  DOWNLOAD_PAGE: 'DOWNLOAD_PAGE',
};

export const BENEFIT_OPTIONS = {
  MULTILINGUAL: 'MULTILINGUAL',
  FAST_LOADING: 'FAST_LOADING',
  INTERNET_SAVING: 'INTERNET_SAVING',
};

export const benefitPoints = {
  1: 'Application-only Sports, Casino & Games Promotions',
  2: 'Exclusive Odds for 4+ Sports Types',
  3: 'Flexible Platform for Easy and Fast Betting',
};

export const howToInstallTexts = {
  1: 'Open Browser ⇨ pmbet.co.tz ⇨ Menu',
  2: 'Click Download Application',
  3: 'Click Download Now',
  4: 'Choose Download anyway',
  5: 'Click Settings',
  6: 'Turn on Allow from this source',
  7: 'Select Install',
};
