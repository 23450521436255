import React from 'react';
import s from './APKWindow.module.scss';
import { images } from '../../../assets/imgs';
import { useTranslation } from '../../../context/LanguageContext';
import classNames from 'classnames';

function APKWindow({ setApkWindow, footer }) {
  const t = useTranslation();

  return (
    <div className={classNames(s.apkWindow, { [s.footer]: footer })}>
      <img className={s.apkLogo} alt="APK Logo" src={images.APKLogo} />
      <div className={s.apkdescription}>
        <div className={s.apkDescriptionContainer}>
          <div>
            <div className={s.apkName}>PMbet Android APP</div>
            <div className={s.apkdescriptiontext}>
              {t(
                'For Android 5.1 or more recent versions. 73 MB without cache, 122 MB with cache.',
              )}
            </div>
            <div className={s.apkVersion}>APK | Version 2.0</div>
          </div>
          {!footer && (
            <div onClick={() => setApkWindow(false)}>
              <img className={s.crossIcon} alt="cross Icon" src={images.crossIcon} />
            </div>
          )}
        </div>
        {!footer && (
          <a
          href="https://assets-inter.energaming.services/apk/pmapp.apk?v=2"
            download="pmapp.apk"
            target="_blank"
            className={s.downloadButton}>
            <img className={s.downloadIcon} alt="download Icon" src={images.downloadIcon} />
            <div>{t('DOWNLOAD')}</div>
          </a>
        )}
      </div>
    </div>
  );
}

export default APKWindow;
