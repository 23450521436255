import androidHead from './androidHead.png';
import fire from './fire.png';
import fastLoadingLogo from './Benefits/fastLoadingLogo.svg';
import internetSavingLogo from './Benefits/internetSavingLogo.svg';
import multilingualLogo from './Benefits/multilingualLogo.svg';
import benefitPointLogo from './benefitPointLogo.svg';
import tennisBall from './tennisBall.png';
import football from './football.png';
import basketball from './basketball.png';
import prematchIcon from './prematchIcon.png';
import liveIcon from './liveIcon.png';
import cashBack from './promos/cashBack.png';
import bonus from './promos/bonus.png';
import jackpot from './promos/jackpot.png';
import freeBet from './promos/freeBet.png';
import dice from './dice.png';
import card from './card.png';
import casinoIcon from './casinoIcon.svg';
import ai from './Providers/ai.svg';
import endorphina from './Providers/endorphina.svg';
import playson from './Providers/playson.png';
import pp from './Providers/pp.svg';
import top from './top.svg';
import APKLogo from './APKLogo.png';
import downloadIcon from './downloadIcon.png';
import crossIcon from './crossIcon.svg';
import screen1 from './ScreenShots/screen-1.png';
import screen2 from './ScreenShots/screen-2.png';
import screen3 from './ScreenShots/screen-3.png';
import screen4 from './ScreenShots/screen-4.png';
import screen5 from './ScreenShots/screen-5.png';
import screen6 from './ScreenShots/screen-6.png';
import screen7 from './ScreenShots/screen-7.png';
import android from './android.svg';
import videoPlayerIcon from './videoPlayerIcon.png';
import SlideArrowRight from './SlideArrowRight.svg';
import SlideArrowLeft from './SlideArrowLeft.svg';
import SlideArrowLeftDisable from './SlideArrowLeftDisable.svg';
import SlideArrowRightDisable from './SlideArrowRightDisable.svg';

export const images = {
  androidHead,
  fire,
  benefitPointLogo,
  tennisBall,
  football,
  basketball,
  prematchIcon,
  liveIcon,
  dice,
  card,
  casinoIcon,
  top,
  APKLogo,
  downloadIcon,
  crossIcon,
  android,
  videoPlayerIcon,
  SlideArrowRight,
  SlideArrowLeft,
  SlideArrowLeftDisable,
  SlideArrowRightDisable,
};

export const benefits = {
  fastLoadingLogo,
  internetSavingLogo,
  multilingualLogo,
};

export const promos = {
  cashBack,
  bonus,
  jackpot,
  freeBet,
};

export const providers = {
  ai,
  pp,
  playson,
  endorphina,
};

export const screenShots = {
  screen1,
  screen2,
  screen3,
  screen4,
  screen5,
  screen6,
  screen7,
};
