import { benefits } from '../assets/imgs';
import { BENEFIT_OPTIONS } from '../constant/app.constat';

const { MULTILINGUAL, FAST_LOADING, INTERNET_SAVING } = BENEFIT_OPTIONS;

export const BENEFIT_CONFIGS = {
  [MULTILINGUAL]: {
    text: 'Multilingual',
    logo: benefits.multilingualLogo,
    backClass: 'multilingualBack',
    langs: 'ENG, SW, CH, FR',
  },
  [FAST_LOADING]: {
    text: 'Faster Loading',
    logo: benefits.fastLoadingLogo,
    backClass: 'fastLoadingBack',
  },
  [INTERNET_SAVING]: {
    text: 'Internet-Saving',
    logo: benefits.internetSavingLogo,
    backClass: 'internetSavingBack',
  },
};
