import React from 'react';
import s from './BenefitOptionsDesktop.module.scss';
import { BENEFIT_CONFIGS } from '../../../../configs/benefit.configs';
import { BENEFIT_OPTIONS, benefitPoints } from '../../../../constant/app.constat';
import classNames from 'classnames';
import { useTranslation } from '../../../../context/LanguageContext';
import { images } from '../../../../assets/imgs';

function BenefitOptionsDesktop() {
  const t = useTranslation();
  return (
    <div className={s.benefitOptionsDesktop}>
      {Object.keys(BENEFIT_OPTIONS).map((option, index) => {
        const text = BENEFIT_CONFIGS[option].text;
        const logo = BENEFIT_CONFIGS[option].logo;
        const backClass = BENEFIT_CONFIGS[option].backClass;
        const langs = BENEFIT_CONFIGS[option].langs;
        const benefitPointsIndex = index + 1;

        return (
          <div className={s.benefitOptionsPoints}>
            <div key={index} className={classNames(s.optionContainer)}>
              <div className={classNames(s.option, s[backClass])}>
                <img className={s.benefitLogo} alt="logo" src={logo} />
                <div className={s.benefitText}>{t(text)}</div>
                {langs && <div className={s.langs}>{langs}</div>}
              </div>
            </div>
            <div className={s.pointSectionDesktop}>
              <img
                className={s.pointLogoDesktop}
                alt="benefit point logo"
                src={images.benefitPointLogo}
              />
              <div className={s.pointTextDesktop}>{t(benefitPoints[benefitPointsIndex])}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default BenefitOptionsDesktop;
