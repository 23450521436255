import React from 'react';
import s from './MainSection.module.scss';
import { images } from '../../../assets/imgs';
import { useTranslation } from '../../../context/LanguageContext';
import FixedButtons from '../FixedButtons/FixedButtons';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { selectActiveLanguage } from '../../../redux/slices/app.slices';

function MainSection() {
  const t = useTranslation();
  const activeLanguage = useSelector(selectActiveLanguage);
  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  return (
    <div className={s.mainSection}>
      <div className={s.titleContainer}>
        <div className={s.androidHeadContainer}>
          <img className={s.androidHead} alt="android head" src={images.androidHead} />
        </div>
        {activeLanguage === 'en' ? (
          <div className={s.mainTitleContainer}>
            <div className={s.firstText}>{t('ALL YOUR')}</div>
            <div className={s.secondText}>{t('WINNINGS')}</div>
            <div className={s.thirdTextContainer}>
              <div className={s.greenText}>{t('IN ONE')}</div>
              {t('ANDROID APP')}
            </div>
          </div>
        ) : (
          <div className={s.mainTitleContainer}>
            <div className={s.secondText}>{t('WINNINGS')}</div>
            <div className={s.firstText}>{t('ALL YOUR')}</div>
            <div className={s.thirdTextContainerSw}>
              <div className={s.thirdtextSw}>{t('ANDROID APP')}</div>
              <div className={s.greenText}>{t('IN ONE')}</div>
            </div>
          </div>
        )}
      </div>
      <div className={s.fixedButtonMargin}>
        <FixedButtons />
      </div>
      {isMobile && (
        <div className={s.secondTitle}>
          <div className={s.firstLine}>
            {t('Now the')}
            <img className={s.fire} alt="fire" src={images.fire} />
            <div className={s.hottest}>{t('Hottest')}</div>
          </div>
          <div className={s.secondLine}>{t('Entertainment is More')}</div>
        </div>
      )}
    </div>
  );
}

export default MainSection;
