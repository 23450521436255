import { createSlice } from '@reduxjs/toolkit';
import { APP_PAGES } from '../../constant/app.constat';

const { LANDING_PAGE } = APP_PAGES;

const initialState = {
  activePage: LANDING_PAGE,
  activeLanguage: '',
  videoPlayer: false,
  activeSlide: 0,
};

export const appSlices = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActivePage: (state, { payload }) => {
      state.activePage = payload;
    },
    setActiveLanguage: (state, { payload }) => {
      state.activeLanguage = payload;
    },
    setVideoPlayerActive: (state, { payload }) => {
      state.videoPlayer = payload;
    },
    setActiveSlide: (state, { payload }) => {
      state.activeSlide = payload;
    },
  },
});

// ACTIONS
export const { setActivePage, setActiveLanguage, setVideoPlayerActive, setActiveSlide } =
  appSlices.actions;

// SELECTORS
export const selectActivePage = (state) => state.app.activePage;
export const selectActiveLanguage = (state) => state.app.activeLanguage;
export const selectVideoPlayer = (state) => state.app.videoPlayer;
export const selectActiveSlide = (state) => state.app.activeSlide;

export default appSlices.reducer;
