import React from 'react';
import s from './LiveSection.module.scss';
import { useTranslation } from '../../../context/LanguageContext';
import { images } from '../../../assets/imgs';

function LiveSection() {
  const t = useTranslation();
  return (
    <div className={s.liveSection}>
      <img className={s.basketball} alt="basketball" src={images.basketball} />
      <div className={s.livePresent}>
        <div className={s.liveTitle}>
          <img className={s.liveIcon} alt="live icon" src={images.liveIcon} />
          <div>{t('Live')}</div>
        </div>
        <div className={s.liveOption}>
          <div className={s.sports}>{`80+ ${t('Sports')}`}</div>
          <div>{`200 ${t('Countries')}`}</div>
        </div>
        <div className={s.countContainer}>
          <div>
            <div className={s.count}>
              ~<div className={s.colorGreen}>95 000+</div>
            </div>
            <div>{t('Matches monthly')}</div>
          </div>
          <div className={s.greenLine}></div>
          <div>
            <div className={s.count}>
              ~<div className={s.colorGreen}>1000+</div>
            </div>
            <div>{t('Bet types')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveSection;
