import React from 'react';
import s from './LandingPage.module.scss';
import MainSection from './MainSection/MainSection';
import BenefitSection from './BenefitSection/BenefitSection';
import PrematchSection from './PrematchSection/PrematchSection';
import LiveSection from './LiveSection/LiveSection';
import CasinoSection from './CasinoSection/CasinoSection';
import FixedButtons from './FixedButtons/FixedButtons';
import { useMediaQuery } from 'react-responsive';
import FooterSection from './FooterSection/FooterSection';

function LandingPage() {
  const isMobile = useMediaQuery({
    query: '(max-width: 1019px)',
  });

  return (
    <div className={s.landingPage}>
      <MainSection />
      <BenefitSection />
      <div className={s.preLiveSections}>
        <PrematchSection />
        <LiveSection />
      </div>
      <CasinoSection />
      {!isMobile && <FooterSection />}
      {isMobile && <FixedButtons />}
    </div>
  );
}

export default LandingPage;
